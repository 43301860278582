<template>
  <Modal closeSize="pt-0.5 h-7 w-7" closePosition="top-6 right-4.25">
    <div class="py-6 px-7.5 root">
      <div class="font-bold text-textgray-900 text-2xl">Autopilot</div>
      <div class="mt-2.25 text-sm text-primary-600">
        Automatic editing to make your recording sound better 😉
      </div>

      <div class="mt-6 relative">
        <div class="flex inset-0 absolute items-center">
          <div class="border-t border-primary-300 w-full" />
        </div>
        <div class="flex relative items-center justify-between">
          <span class="bg-white font-medium text-sm text-primary pr-2">
            Filler word Removal
          </span>

          <div class="bg-white flex pl-2 items-center">
            <VueSwitch v-model="isFillerwordsEnabledModel" />
          </div>
        </div>
      </div>

      <div class="mt-2.5 text-xs mb-2 text-primary-600">
        Define custom filler words:
      </div>

      <div class="flex flex-wrap items-center">
        <div v-for="word in newFillerWords" :key="word" class="bg-primary-200 rounded-0.75 mt-2 mr-3 py-1.25 px-2.5 inline-flex items-center">
          <div class="text-xs">{{word}}</div>
          <div @click="updateNewFillerWords(word)" class="cursor-pointer ml-2" >
            <CloseIcon classname="h-3 w-3" />
          </div>
        </div>

        <input
          v-if="isAddingFillerWord"
          v-focus
          v-model="newFillerWordModel"
          @blur="addNewFillerWord"
          @keyup.enter="addNewFillerWord"
          class="rounded h-6 mt-2 text-xs mr-3 px-2 w-14 focus:outline-none focus:ring-primary focus:ring-2"
        />

        <div
          v-if="newFillerWords && newFillerWords.length < 18"
          class="cursor-pointer mt-2 text-primary-400 hover:text-primary-700" @click="isAddingFillerWord = true;"
          :class="{'transform rotate-45': isAddingFillerWord}"
        >
          <AddIcon classname="h-5 w-5" />
        </div>
      </div>

      <div class="mt-6 relative">
        <div class="flex inset-0 absolute items-center">
          <div class="border-t border-primary-300 w-full" />
        </div>
        <div class="flex relative items-center justify-between">
          <span class="bg-white font-medium text-sm text-primary pr-2">
            Silence Trimmer
          </span>

          <div class="bg-white flex pl-2 items-center">
            <VueSwitch v-model="isTrimSilenceEnabledModel" />
          </div>
        </div>
      </div>

      <div class="flex mt-2.5 text-xs text-primary-600 items-center">
        Allow silences upto
        <input
          v-if="isEditingSilenceUpto"
          v-focus
          v-model.number="silenceUptoModel"
          @blur="isEditingSilenceUpto = false;"
          @keyup.enter="isEditingSilenceUpto=false;"
          type="number"
          min="1"
          max="9"
          class="border rounded h-5 text-xs p-0 w-6 silenceUptoInput focus:outline-none focus:border-primary focus:ring-0"
        />
        <div v-else @click="isEditingSilenceUpto = true;" class="rounded cursor-pointer bg-primary-200 h-5 mx-1.25 w-6 silenceUptoInput inline-flex items-center justify-center">
          {{newSilenceupto}}
        </div>
         seconds
      </div>

      <div class="mt-6 relative">
        <div class="flex inset-0 absolute items-center">
          <div class="border-t border-primary-300 w-full" />
        </div>
        <div class="flex relative items-center justify-between">
          <span class="bg-white font-medium text-sm text-primary pr-2">
             Noise Reduction
          </span>

          <div class="bg-white flex pl-2 items-center">
            <VueSwitch v-model="isNoiseReductionEnabledModel" />
          </div>
        </div>
      </div>

      <div class="rounded flex bg-primary-100 mt-5 py-2 px-3 items-center">
        <div>💡</div>
        <div class="text-xs">These happen only during export. So it may not reflect in your preview, but we're on it 😉 </div>
      </div>

      <div class="flex mt-5 items-center">
        <div class="flex w-52 items-center">
          <input
            type="checkbox"
            id="cleanBgNoise"
            value="cleanBgNoise"
            v-model="nrConfigModel"
            :disabled="!newNoiseReductionEnabled"
          >
          <label for="cleanBgNoise" class="cursor-pointer flex text-xs text-primary-600 items-center">Clean background noise</label>
        </div>

        <div class="flex w-48 items-center">
          <input type="checkbox" id="voiceLevelling" value="voiceLevelling" v-model="nrConfigModel" :disabled="!newNoiseReductionEnabled">
          <label for="voiceLevelling" class="cursor-pointer flex text-xs text-primary-600 items-center">Voice Levelling</label>
        </div>

        <div class="flex w-48 items-center">
          <input type="checkbox" id="boostSpeaker" value="boostSpeaker" v-model="nrConfigModel" :disabled="!newNoiseReductionEnabled">
          <label for="boostSpeaker" class="cursor-pointer flex text-xs text-primary-600 items-center">Boost speaker voice</label>
        </div>
      </div>

      <div v-if="success" class="flex mt-6 justify-end">
        <Button
          key="1"
          variant="success"
        >
          Applied
        </Button>
     </div>
     <div v-else class="flex mt-6 justify-end">
        <Button
          key="2"
          :onClick="applyChanges"
          :variant="isDirty ? 'primary' : 'disabled'"
        >
          Apply All Changes
        </Button>
     </div>
    </div>
  </Modal>
</template>

<script>
import isEqual from 'lodash/isEqual'
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import VueSwitch from '@/components/base/inputs/Switch'
import CloseIcon from '@/components/base/icons/Close.vue'
import AddIcon from '@/components/base/icons/Add.vue'
import Button from '@/components/base/buttons/Button.vue'

const findAndToggleItem = (arr, item) => {
  if (!Array.isArray(arr)) return []
  const i = arr.findIndex(el => el === item)
  return i === -1 ? [...arr, item] : [...arr.slice(0, i), ...arr.slice(i + 1)]
}

export default {
  name: 'SmartEditModal',
  data: function() {
    return {
      isAddingFillerWord: false,
      isEditingSilenceUpto: false,
      newFillerWord: '',
      newFillerWords: [],
      newSilenceupto: '',
      newNrConfig: [],
      newFillerWordsEnabled: false,
      newTrimSilenceEnabled: false,
      newNoiseReductionEnabled: false,
      success: false
    }
  },
  components: {
    Modal,
    VueSwitch,
    CloseIcon,
    AddIcon,
    Button
  },
  computed: {
    ...mapGetters({
      fillerWords: 'smartEditConfig/fillerWords',
      silenceUpto: 'smartEditConfig/silenceUpto',
      trimSilence: 'smartEditConfig/trimSilence',
      allFillerWords: 'smartEditConfig/allFillerWords',
      noiseReduction: 'smartEditConfig/noiseReduction',
      nrConfig: 'smartEditConfig/nrConfig',
      isFillerWordsEnabled: 'smartEditConfig/isFillerWordsEnabled',
      isTrimSilenceEnabled: 'smartEditConfig/isTrimSilenceEnabled',
      isNoiseReductionEnabled: 'smartEditConfig/isNoiseReductionEnabled',
      modalProps: 'dialogs/modalProps'
    }),
    isFillerwordsEnabledModel: {
      get: function() {
        return this.newFillerWordsEnabled
      },
      set: function(val) {
        this.newFillerWordsEnabled = val
      }
    },
    isTrimSilenceEnabledModel: {
      get: function() {
        return this.newTrimSilenceEnabled
      },
      set: function(val) {
        this.newTrimSilenceEnabled = val
      }
    },
    isNoiseReductionEnabledModel: {
      get: function() {
        return this.newNoiseReductionEnabled
      },
      set: function(val) {
        this.newNoiseReductionEnabled = val
      }
    },
    newFillerWordModel: {
      get: function() {
        return this.newFillerWord
      },
      set: function(val) {
        this.newFillerWord = val
      }
    },
    silenceUptoModel: {
      get: function() {
        return this.newSilenceupto
      },
      set: function(val) {
        if (Number(val) > 0) this.newSilenceupto = val
      }
    },
    nrConfigModel: {
      get: function() {
        return this.newNrConfig
      },
      set: function(val) {
        if (this.newNoiseReductionEnabled) this.newNrConfig = val
      }
    },
    isDirty: function() {
      if (
        isEqual(this.newFillerWords, this.allFillerWords) &&
        isEqual(this.newSilenceupto, this.silenceUpto) &&
        isEqual(this.newNrConfig, this.nrConfig) &&
        isEqual(this.newFillerWordsEnabled, this.isFillerWordsEnabled) &&
        isEqual(this.newTrimSilenceEnabled, this.isTrimSilenceEnabled) &&
        isEqual(this.newNoiseReductionEnabled, this.isNoiseReductionEnabled)
      ) return false

      return true
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal',
      openModal: 'dialogs/openModal',
      updateConfig: 'smartEditConfig/updateConfig'
    }),
    updateNewFillerWords: function(word) {
      this.newFillerWords = findAndToggleItem(this.newFillerWords, word)
    },
    addNewFillerWord: function() {
      if (this.newFillerWord.trim()) this.updateNewFillerWords(this.newFillerWord)
      this.isAddingFillerWord = false
      this.newFillerWord = ''
    },
    applyChanges: function() {
      this.updateConfig({
        podId: this.$route.params.podId,
        ownerId: this.$route.params.userId,
        silenceUpto: this.newSilenceupto,
        words: this.newFillerWords,
        nrConfig: this.newNrConfig,
        isFillerWordsEnabled: this.newFillerWordsEnabled,
        isTrimSilenceEnabled: this.newTrimSilenceEnabled,
        isNoiseReductionEnabled: this.newNoiseReductionEnabled
      })
      this.success = true
      setTimeout(() => {
        this.success = false
        this.closeModal()
      }, 800)
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  },
  created: function() {
    this.newFillerWords = this.allFillerWords || []
    this.newSilenceupto = this.silenceUpto
    this.newNrConfig = this.nrConfig
    this.newFillerWordsEnabled = this.isFillerWordsEnabled
    this.newTrimSilenceEnabled = this.isTrimSilenceEnabled
    this.newNoiseReductionEnabled = this.isNoiseReductionEnabled
    if (this.modalProps && this.modalProps.isAddingFillerWord) this.isAddingFillerWord = true
  },
  watch: {
    nrConfig: function (val, oldVal) {
      if (val !== oldVal) this.newNrConfig = val
    },
    allFillerWords: function (val, oldVal) {
      if (val !== oldVal) this.newFillerWords = val
    },
    silenceUpto: function (val, oldVal) {
      if (val !== oldVal) this.newSilenceupto = val
    },
    isFillerWordsEnabled: function (val, oldVal) {
      if (val !== oldVal) this.newFillerWordsEnabled = val
    },
    isTrimSilenceEnabled: function (val, oldVal) {
      if (val !== oldVal) this.newTrimSilenceEnabled = val
    },
    isNoiseReductionEnabled: function (val, oldVal) {
      if (val !== oldVal) this.newNoiseReductionEnabled = val
    }
  }
}
</script>

<style scoped lang="css">
.closeClass svg {
  height: 28px !important;
  width: 28px !important;
}

.root {
  width: 620px;
}

.silenceUptoInput {
  text-align: center;
  margin: 0 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  padding-left: 30px;
}

label::before {
  top: -1px !important;
}

label:after {
  top: 8px !important;
}
</style>
